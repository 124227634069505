/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"); */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    color: white;
    /* font-size: 1rem; */
}

#start {
    display: flex;
    flex-direction: column;
}

/* .app-body {
    background-image: url("/public/background1.jpg");
    background-size: contain;
} */

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.placeHolder {
    height: 40vh;
}

.sectionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.projectsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.projectBox {
    border-radius: 0.75rem;
    padding: 1.25rem;
    width: 200px;
    background: rgb(68, 67, 67);
    flex-basis: 45%;
    margin-bottom: 3vh;
}

.shadow{
    box-shadow: 4px 10px 5px rgba(0, 0, 0, 0.726);

}

.projectBox:hover,
.contactWrapper:hover {
    animation: pulse;
    animation-duration: 2s;
}

.imageBox {
    height: 230px;
    position: relative;
    width: 100%;
}

.projectGif {
    border-radius: 0.5rem;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.projectText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    position: relative;
    width: 100%;
}

.projectText h4 {
    line-height: 1.5rem;
    margin-top: 2rem;
    font-weight: 900;
    max-width: 600px;
}

.projectText p,
.projectText a {
    align-self: flex-start;
    font-weight: 500;
    margin: 2rem;
}

.projectText a {
    text-decoration: underline;
}

.skillWrapper,
.topSkill,
.bottomSkill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.topSkill ul,
.bottomSkill ul {
    width: 13vw;
}

.skillBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

#intro,
#projects,
#contact {
    /* background-color: rgb(53, 77, 54); */
    background-image: radial-gradient(rgb(99, 130, 101), rgb(83, 80, 80)) ;
}
#about,
#skills{
    /* background-color: rgb(71, 104, 72); */
    background-image: radial-gradient(  rgb(71, 104, 72), rgb(72, 72, 72));
    /* background-color: transparent; */
}

.projectBox {
    /* background-color: rgb(50, 71, 51); */
    background-image: linear-gradient(163deg, rgb(50, 71, 51) 40%, black);
}

.contactWrapper {
    background-image: linear-gradient(123deg, rgb(50, 71, 51) 75%, black );
}

li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1vw;
}

.contactLogo {
        width: 7vh;
    height: 7vh;
}

#intro,
#about,
#contact,
#skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contactWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid rgb(71, 104, 72);
    border-radius: 0.75rem;
    gap: 3vh;
    padding: min(10px, 2vh);

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.stackLogo {
    width: 3vh;
}

#intro,
#about,
#projects,
#skills,
#contact {
    min-height: 90vh;
}

#intro h2 {
    height: 30vh;
    width: 40vw;
}

h1, h2, h3, h4 {
    text-transform: uppercase;
}

.thick__text,
h1.welcomeText,
.projectHeader {
    font-weight: 800;
}

.welcomeText,
.aboutWrapper h3 {
    font-weight: 380;
}

.aboutWrapper {
    width: 50vw;
}

.aboutWrapper h2,
.projectHeader,
.contactHeader {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.logoContainer {
    display: flex;
    gap: min(2vw, 20px);
}