html {
    /* needed so that anchor-tags are not overlapped by header */
    scroll-padding-top: 10vh;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(40, 44, 52, 0.83);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: fixed;
    height: 10vh;
    min-height: 35px;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(3px);
}

.app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.app-body {
    margin-top: 10vh;
    background-color: darkgray;
}

a {
    color: white;
    text-decoration: none;
}

#profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.profileLogo {
    width: 4vh;
    height: 4vh;
}
